@import "themes/default";
.footer{
  color: #495770;
  font-size: 16px;
  background-color: #f5f7fa;
  .ant-typography{
    color: @gray-one-color;
  }
  .footer-desc{
    margin-top: 10px;
    line-height: 1.8;
  }
  .footer-content{
     margin-right: 30px;
     ul,li{
       margin: 0;
       padding: 0 0 5px;
       list-style: none;
     }
     a{
        color: #495770;
       &:hover{
         color: @foot-link-color-hover
       }
     }
  }
  .ant-col:last-child{
    .footer-content{
      margin-right: 0;
    }
  }
  .footer-desc{
    p{
      margin-bottom: 0;
      i{
        margin-right: 3px;
        &.icon-phone{
          margin-right: 6px;
        }
      }
    }
    padding-bottom: 15px;
  }
}
.footer-write{
  padding: 30px 0;
}
.footer-copyright{
  text-align: center;
  border-top:#d3d7de solid 1px;
  padding: 15px 0;
  font-size: 16px;
  color:#495770;
}
.footer-code{
  background-color: @white-color;
  box-shadow: 0px 1px 18px 0px rgba(32, 79, 221, 0.25);
  width: 120px;
  img{
    display: block;
  }
}
