@import "mixins/height";
@import "themes/default";

/* 全局背景色*/
@font-face {
  font-family: "PingFang";
  src: url('../css/font/PingFang Medium.ttf') format('truetype');
}

html > body {
  min-width: 1220px;
  background-color: @component-background;
  font-family: "PingFang";
}

/* 全局背景色end*/
.not-border {
  border: none !important;
}

/* 字体修改 */
body {
  width: 100% !important;

  .ant-list-bordered .ant-list-item {
    padding: 16px;
  }

  .ant-list-vertical .ant-list-item-meta-title {
    font-size: 18px;
  }

  .ant-card-head {
    padding: 0 16px;
    font-size: 18px;
  }

  .ant-card-head-tabs .ant-tabs-tab {
    font-size: 18px !important;
  }

  .ant-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ant-table {
    font-size: 16px;

    a {
      // color: rgba(0, 0, 0, 0.85);

      &:hover {
        color: @primary-color;
      }
    }
  }

  .ant-card-body {
    padding: 16px;
    zoom: 1;
  }

  .ant-list-item-meta-title {
    font-size: 18px;
    line-height: 30px;

    a {
      .ant-typography {
        &:hover {
          color: @primary-color;
        }
      }
    }
  }

  .ant-list-item-meta-title a {
    color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;

    &:hover {
      color: @primary-color;
    }
  }

  .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.65);

    .ant-typography {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-list-item-meta-title {
    .ant-typography {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 100%;
    margin: 0 32px 0 0;
    padding: 16px 0;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-typography {
    color: unset;
  }

  .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
    padding: 16px 0;
  }
}

/*内容区域*/
.container {
  max-width: 1200px;
  min-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

/*内容区域end*/

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.content {
  position: relative;
  overflow: hidden;
  .minHeight;
}

// 面包屑
.crumb-box {
  padding: 20px 0;
  overflow: hidden;

  i {
    color: #000000;
  }

  .ant-breadcrumb-separator {
    color: #000000;
  }

  .ant-breadcrumb-link {
    color: @link-color;
  }

  .ant-breadcrumb a {
    color: #8e8e8e;
    transition: color 0.3s;
  }
}

// 面包屑--end

/*文字排版*/
.ant-text-left {
  text-align: left;
}

.ant-text-right {
  text-align: right;
}

.ant-text-center {
  text-align: center;
}

/*文字排版end*/

/*分页*/
.paging {
  margin: 22px 0 64px;

  .ant-pagination {
    text-align: center;
  }
}

.list-paging {
  margin: 10px 0 64px;

  .ant-pagination {
    text-align: center;

    .ant-pagination-item-active {
      font-weight: 500;
      background: @primary-color;
      border-color: @primary-color;

      a {
        color: #fff;
      }
    }
  }
}

/*分页end*/
.html-content {
  line-height: 2;
}

/*列表边框颜色*/
.ant-list.ant-list-bordered {
  border-color: #e8e8e8;
}

/*列表边框颜色end*/

/*主题色className*/
.primary-color {
  color: @primary-color !important;
}

/*主题色className end*/

/* 重点颜色 */
.sft-color {
  color: #0fbebe !important;
}

/* 重点颜色 end*/
/*黑色标题字体颜色*/
.title-text {
  color: #000000 !important;
}

.text {
  color: rgba(0, 0, 0, 0.45) !important;
}

.fu-text {
  color: rgba(0, 0, 0, 0.65);
}

/* 间距调整 */
.ant-list-item-meta-description,
.ant-list {
  line-height: 26px !important;
}

.ant-list-vertical .ant-list-item-meta-title {
  line-height: 28px !important;
}

// .ant-card,
// .ant-popover-inner-content,
// .ant-list-item-meta-description,
// .ant-list-item-meta-title,
// .ant-list {
//     color: rgba(0, 0, 0, 0.85) !important;
// }
.ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: text-bottom !important;
}

/*标题字体颜色--end*/

/*灰色className*/
.gray-one-color {
  color: @gray-one-color !important;
}

.gray-tow-color {
  color: @gray-two-color !important;
}

.text {
  color: @text !important;
}

.gray-four-color {
  color: @gray-four-color !important;
}

.gray-five-color {
  color: @gray-five-color !important;
}

/*灰色className end*/

/*白色背景色*/
.background-white {
  background-color: @white-color !important;
}

/*白色背景色end*/

/*边框*/
.border {
  border: #e8e8e8 solid 1px;
}

.border-top {
  border-top: #e8e8e8 solid 1px;
}

.border-bottom {
  border-bottom: #e8e8e8 solid 1px !important;
}

.border-right {
  border-right: #e8e8e8 solid 1px;
}

/*边框end*/

/*search-搜素栏样式*/
.search-head {
  position: relative;
  overflow: hidden;
  width: 300px;
  padding-bottom: 15px;
}

/*search-搜素栏样式-end*/

// 自定义table样式

.my-ant-table {
  width: 100%;
  border-radius: 4px 4px 0 0;
  border-collapse: collapse;
  border: 1px solid #f0f0f0;
  border-right: 0;
  table-layout: fixed;
  border-bottom: 0;
  text-align: left;

  thead > tr {
    transition: all 0.3s, height 0s;
  }

  thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px;
  }

  thead > tr > th,
  tbody > tr > td {
    border-right: 1px solid #f0f0f0;
    padding: 16px 16px;
  }

  thead > tr > th {
    padding: 16px 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
  }

  tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: all 0.3s, border 0s;
  }
}

//首页大号按钮
.home-footer-btn {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 200px;
  margin: 0 auto;
}

//首页大号按钮-end

// 回到顶部锚点样式
.scoll-top {
  .ant-back-top-content {
    background-color: @primary-color;

    &:hover {
      background-color: @primaryHover-color;
    }
  }
}

// 回到顶部锚-end点样式

/*表单*/
.form-divider-text-size {
  .ant-divider-inner-text {
    font-size: 20px;
  }
}

.form-default-style {
  h3 {
    p {
      display: inline-block;
      margin: 0;
    }
  }

  .ant-form-item-label {
    line-height: 35px;
    white-space: unset;

    label {
      font-size: 16px;
      padding-right: 16px;
      height: auto;

      &:after {
        content: ":";
        position: absolute;
        top: 0;
        margin: 0 8px 0 2px;
        right: 0;
      }
    }
  }

  .ant-form-item-control {
    justify-content: end;
  }

  .ant-btn {
    height: auto;
    font-size: 16px;
  }

  .ant-picker-input > input {
    font-size: 16px;
  }

  .ant-form-item {
    margin-bottom: 32px;
  }

  .ant-input,
  .ant-select-selection-item {
    font-size: 16px;
  }

  &.label-row {
    .ant-spin-container {
      .ant-radio-wrapper {
        display: block;
        margin-bottom: 16px;
        overflow: hidden;
        white-space: inherit;
        text-overflow: ellipsis;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .ant-checkbox-wrapper {
      display: block;
      margin: 0 0 16px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item-control-input-content {
    font-size: 16px;
  }
}

.room-name {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}

.name-label {
  width: 100px;
  display: inline-block;
  margin-right: 5px;
}

.form-line {
  line-height: 24px;
}

.inline-block {
  display: inline-block;
}

.price {
  color: @error-color;
  font-size: 18px;
}

/*表单end*/

/*字体 12px-32px*/
.cont(@count) when (@count > 10) {
  .cont((@count - 1));

  .size-@{count} {
    font-size: (1px * @count) !important;
  }
}

.cont(50);
/*字体 12px-50px end*/

/*card 内部 body 边距*/

.card(@count) when (@count >=0) {
  .card((@count - 1));

  .card-@{count} {
    .ant-card-body {
      padding: 0 (1px * @count) !important;
    }
  }
}

.card(100);

/*card 内部 body 边距 --end*/

.padding(@count) when (@count >=0) {
  .padding((@count - 1));

  .card-space-@{count} {
    .ant-card-body {
      padding: (1px * @count) 16px !important;
    }
  }
}

.padding(100);

/*日期选择100%宽度样式*/
.date-type-block {
  &.ant-calendar-picker {
    display: block;
  }
}

/*日期选择100%宽度样式end*/

/* 重定义*/
.ant-tag-blue {
  color: @primary-color !important;
  background: #e6f7ff;
  border-color: @primary-color !important;
}

/* 重定义*/

/*个人中心menu*/
.my-menu {
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 50px;
    line-height: 50px;
  }

  .ant-menu-submenu-title {
    height: 64px !important;
    line-height: 64px !important;
    font-size: 16px;
  }

  .ant-menu-submenu {
    .icon {
      margin-right: 10px;
    }
  }

  .ant-menu-item {
    margin-bottom: 5.7px !important;

    a {
      color: @gray-one-color;
    }

    .icon {
      margin-right: 10px;
    }

    &.ant-menu-item-active,
    &.ant-menu-item-selected {
      a {
        color: @primary-color;
      }
    }
  }

  .ant-menu-item-selected {
    background-color: @primary-color !important;
    color: #fff;

    &:hover {
      color: #fff;
    }

    &::after {
      border-right: 3px solid #40a9ff;
    }
  }
}

/*个人中心menu end*/

/*卡片内容区域边距*/
.card-p10 {
  .ant-card-body {
    padding: 10px;
  }
}

.card-p10-24 {
  .ant-card-body {
    padding: 10px 24px;
  }
}

/*卡片内容区域边距-end*/

/*卡片类型*/
.card-state {
  .ant-card-body {
    padding: 15.2px 24px;
    text-align: center;
  }

  p {
    color: #666;
    margin: 0;
    font-size: 12px;
  }
}

/*卡片类型-end*/

/*圆形卡片*/
.rotundity-card {
  padding: 11px 0;
  text-align: center;
  cursor: pointer;
  display: block;

  i {
    font-size: 30px;
  }

  p {
    margin-bottom: 0 !important;
    margin-top: 5px;
    color: #333333;
  }
}

/*圆形选项卡-end*/

/*大号圆形卡片*/
.big-card-setting {
  width: 156px;
  height: 156px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  line-height: 156px;

  img {
    width: 100%;
    height: 100%;
  }
}

/*圆形选项卡-end*/

/*折叠面板-*/
.down-table {
  .ant-collapse-header {
    position: absolute !important;
    right: 0;
    top: 70px;
    margin-top: -25px;
  }

  .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-content-box {
    padding: 10px 0 0 0 !important;
  }
}

/*折叠面板-end*/

/* table fixed 布局 */
.table-fixed {
  table {
    table-layout: fixed;
  }
}

/* table fixed - end 布局 */

/*margin-centet */
.margin-centet {
  margin: 0 auto;
}

/*margin-centet-end */

/*上一篇 下一篇 返回*/
.info-article-page {
  .ant-typography {
    border-top: #e6e6e6 solid 1px;
    border-bottom: #e6e6e6 solid 1px;
    margin: 0;
    padding: 10px 0;
    color: @gray-one-color;
    font-size: 15px;

    &:first-child {
      border-bottom: none;
    }

    span {
      font-size: 14px;
    }

    a {
      color: #7d7d7d;
      font-size: 14px;

      &:hover {
        color: @primary-color;
      }
    }
  }

  .line {
    margin: 0 10px;
    color: #d9d9d9;
  }
}

.info-back {
  margin-top: 20px;
  text-align: right;
}

/*上一篇 下一篇 返回 end*/

// html content 文字换行
.html-details {
  ul {
    width: 100% !important;
    white-space: normal !important;
  }

  p {
    display: inline-block;
  }

  img {
    max-width: 100%;
    max-height: 400px;
  }
}

/*图片轮播Carousel*/
.atlas-cl {
  .ant-carousel {
    .slick-list {
      overflow: hidden;
      width: 830px;
      margin: 0 auto;
    }

    .slick-slide {
      text-align: center;
      pointer-events: auto !important;
      height: 100% !important;
      background-color: #f5f5f5;
      overflow: hidden;
      width: 200px;
      margin-right: 10px;
    }

    .slick-prev,
    .slick-next {
      background-color: @primary-color;
      color: @white-color;
      width: 24px;
      height: 40px;
      line-height: 40px;
      margin-top: -20px;
      z-index: 10;

      &:focus {
        background-color: @primary-color;
        color: @white-color;
      }

      &:hover {
        background-color: @primaryHover-color;
        color: @white-color;
      }
    }

    .slick-prev {
      left: 8px;

      &:before {
        content: "\e665";
        font-family: "icon" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .slick-next {
      right: 8px;

      &:before {
        content: "\e861";
        font-family: "icon" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}

/*图片轮播Carousel end*/

/*颜色*/
/*白色*/
.white-color {
  color: #ffffff !important;
}

/*高亮样式，橙色*/
.highlight-color {
  color: #ff9a1b;
}

/*等待颜色，主题色*/
.wait-color {
  color: #4190ea;
}

/*提示颜色，橙色*/
.loading-color {
  color: #ff9a1b !important;
}

/*成功，灰色*/
.success-color {
  color: #bdbdbd;
}

/*错误，红色*/
.error-color {
  color: #cf2525;
}

.color-waiting {
  color: #4190ea !important;
}

.color-green {
  color: #00c500 !important;
}

.color-success {
  color: #bdbdbd !important;
}

.color-failure {
  color: #ffbe02 !important;
}

.color-cancel {
  color: #cf2525 !important;
}

.color-black {
  color: #000 !important;
}

.color-red {
  color: red !important;
}

.color-waiting-use {
  color: #2289cb !important;
}

.color-waiting-pay {
  color: #009900 !important;
}

/* 颜色end */

.selected {
  color: #4190ea !important;
  font-size: larger;
}

.unselected {
  color: #bdbdbd !important;
}

/*圆角*/
.radius-4 {
  border-radius: 4px !important;
  overflow: hidden;
}

.card-inline {
  > a {
    display: inline-block;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-card.card-inline {
  display: inline-block;
}

.ant-card.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 14px 8px rgba(0, 0, 0, 0.09);
}

.pagination-center {
  .ant-list-pagination {
    text-align: center;
  }

  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: inherit;
  }
}

//评论区样式
.pinglun-box {
  border-bottom: solid 1px #eee;

  .ant-comment-nested {
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 15px;

    .ant-comment {
      border-bottom: #eee solid 1px;

      &:first-child {
        padding-top: 10px;
      }

      &:last-child {
        border: none;
      }
    }

    .ant-comment-inner {
      padding: 15px;
    }
  }

  .ant-comment-content-author {
    padding-right: 50px;
  }

  .ant-comment-content-detail {
    padding-right: 50px;
  }

  .ant-comment-actions {
    margin: 0;
  }

  .ant-comment-actions > li {
    &:first-child {
      position: absolute;
      right: 0;
      top: 0;

      span {
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 5px;
        }

        i {
          color: @primary-color;
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }

    &:last-child {
      position: absolute;
      right: 0;
      top: 23px;

      span {
        padding-right: 0;
      }
    }
  }
}

// .replyon-comment {
//     padding-left: 15px;
//     padding-right: 15px;
//     .ant-comment-nested {
//         border-radius: 5px;
//         background-color: #f9f9f9;
//         // margin-bottom: 15px;
//     }
// }

.comment-box {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;

  .textArea-box {
    position: unset;

    textarea {
      border: none;
      box-shadow: unset;
    }

    .txtlimit {
      line-height: 20px !important;
      position: absolute;
      padding: 0 10px;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 45px;
      text-align: center;
      right: 65px;
      font-size: 14px;
      bottom: 15px;
      color: #999999;
    }
  }

  .comment-submit {
    background-color: #f9f9f9;
    padding: 10px;
    text-align: right;
    line-height: 30px;

    .ant-btn {
      height: 30px;
      padding: 0 10px;
    }
  }
}

.carnet-text {
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  padding: 15px 0;
}

.textArea-box {
  position: relative;

  textarea {
    resize: none;
    margin-bottom: 0 !important;
    outline: none;
    border-radius: 0;
    box-shadow: unset;
  }
}

.txtlimit {
  line-height: 20px !important;
  color: #fff;
  position: absolute;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  text-align: center;
  right: 10px;
  font-size: 12px;
  bottom: 10px;
}

//评论区样式--end

// 盒子外部容器
.box {
  position: relative;
  overflow: hidden;

  .ant-tag {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    white-space: inherit;
  }
}

// 盒子外部容器--end
.main {
  cursor: pointer;
}

.boxShow {
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
}

//锚点菜单
.ant-back-top {
  &.home-backTop {
    .fade-leave {
      i {
        display: none;
      }
    }

    .fade-enter {
      right: 0;
      width: 36px;
      height: 36px;
      top: 0;
      box-shadow: unset;
      border: none;
      background-color: @primary-color;
      text-align: center;
      line-height: 36px;
      color: #fff;
      overflow: unset;

      &:hover {
        background-color: @primaryHover-color;
      }
    }
  }
}

.scoll-top {
  .ant-back-top {
    right: 30px;
  }
}

.backTop-box {
  right: 0;
  width: 36px;
  height: 36px;
  top: 50%;
  transform: translate(0, 150%);
  position: fixed;
  z-index: 1;

  .home-backTop {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  span {
    background-color: @primaryHover-color;
    display: block;
    position: absolute;
    min-width: 70px;
    padding-left: 10px;
    height: 36px;
    right: -70px;
    transition: all 0.3s;
    top: 0;
    color: #fff;
    line-height: 36px;
    font-size: 14px;
  }

  &:hover {
    span {
      right: 36px;
    }
  }
}

.home-ancre {
  position: fixed;
  right: 0;
  width: 36px;
  background-color: @primary-color;
  z-index: 100;
  transition: all 0.2s;
  top: 50%;
  transform: translate(0, -50%);

  &.not-space {
    height: auto;
    width: auto;
    white-space: nowrap;
    text-align: center;
    transform: unset;

    ul li {
      width: auto;
      height: auto;
      padding: 10px;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      position: relative;
      list-style: none;
      width: 36px;
      height: 36px;
      text-align: center;
      margin: 0;
      font-size: 12px;

      &:hover {
        background-color: @primaryHover-color;
      }

      img {
        position: relative;
        width: 16px;
        height: 16px;
        margin: 10px 10px 0;
      }

      span {
        background-color: @primaryHover-color;
        display: block;
        position: absolute;
        min-width: 70px;
        padding-left: 10px;
        height: 36px;
        right: -70px;
        transition: all 0.3s;
        top: 0;
        color: #fff;
        line-height: 36px;
        font-size: 14px;
      }

      &:hover {
        span {
          right: 36px;
        }
      }

      // &:after {
      //   content: "";
      //   position: absolute;
      //   left: 9px;
      //   bottom: 0px;
      //   display: inline-block;
      //   width: 18px;
      //   height: 1px;
      //   background-color: #d1d1d1;
      // }

      a {
        color: #fff;
      }

      i {
        font-size: 16px;
        display: block;
        opacity: 0.8;
        height: 16px;
        line-height: 16px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  &.effect {
    right: -40px;
  }

  &:hover {
    right: 0;
  }
}

.hand {
  cursor: pointer;
}

//行头点标记
.line-label {
  position: relative;
  overflow: hidden;
  // padding-left: 20px;
  cursor: pointer;

  &:hover {
    div.ant-typography,
    .ant-typography p {
      color: #000;
    }
  }

  // &::after {
  //     position: absolute;
  //     content: "";
  //     height: 6px;
  //     width: 6px;
  //     background-color: @primary-color;
  //     left: 0;
  //     top: 50%;
  //     margin-top: -2.5px;
  // }

  div.ant-typography,
  .ant-typography p {
    margin-bottom: 0;
  }
}

//行头点标记--end
/*暂无数据*/
.no-data {
  width: 100%;
  height: 200px;
  text-align: center;
}

/*暂无数据end*/
.bi-hover {
  transition: all 0.4s !important;

  &:hover {
    transform: translateY(-2%);
    box-shadow: 0 4px 12px 0 #ddd;
  }
}

.hand-cover {
  cursor: pointer !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.copy-a-label {
  cursor: pointer !important;
  color: #2289cb;
}

/* banner 图 */
.banner-box {
  position: relative;
  height: 230px;
  background-size: cover;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .banner-text {
    line-height: 220px;
    color: @white-color;
    font-size: 32px;
    text-align: center;
  }
}

/* 按钮样式调整*/
// .ant-btn:hover,
// .ant-btn:focus {
//     color: #ffff !important;
//     background-color: @primary-color !important;
//     border-color: @primary-color !important;
// }

// Card-Head 样式样式
.card-box {
  .ant-card-head {
    padding: 0 12px;
    position: relative;
    border: none;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      height: 16px;
      width: 4px;
      background-color: @primary-color;
      border-radius: 2px;
    }

    .ant-card-head-title {
      color: #000000;
      font-size: 20px;
    }
  }

  .ant-card-body {
    padding: 0;
  }
}

/*详情页*/
.details-html {
  .ant-btn[disabled] {
    pointer-events: none;

    &:hover {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
    }
  }

  .pingj {
    .ant-list-split .ant-list-item {
      border-bottom: 1px solid #f0f0f0;
      align-items: end;
    }
  }

  .ant-card-bordered {
    border: none;

    &.info-details {
      // border: 1px solid #f0f0f0;
    }
  }

  .details-header {
    border: 1px solid #f0f0f0;
    border-radius: 4px;

    > .ant-card-body {
      padding: 0;
    }

    .index-price-img,
    .price-img {
      height: 100%;
      min-height: 260px;

      .images-auto {
        height: 100% !important;
        border-radius: 0;
      }
    }

    .gutter-row {
      &:last-child {
        padding-top: 16px;

        .ant-typography {
          font-size: 24px;
        }
      }
    }

    .details-intro-header {
      .ant-list-item {
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }

  .ant-list-split .ant-list-item {
    border: none;
    // line-height: 35px;
  }

  .ant-card-head {
    padding: 0 !important;
    position: relative;
    border: none;

    .ant-card-head-title {
      position: relative;
      color: #000000;
      font-size: 18px;
      padding: 0 16px 16px 16px !important;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 6px;
        height: 16px;
        width: 4px;
        background-color: #096dd9;
        border-radius: 2px;
      }
    }
  }
}

.popular-content {
  &.ant-card {
    border: none;
  }

  .ant-card-body {
    border: 1px solid #f0f0f0;
    padding: 16px;
    border-radius: 4px;
  }

  .ant-card-head {
    padding: 0 !important;
    position: relative;
    border: none;

    .ant-card-head-title {
      position: relative;
      color: #000000;
      font-size: 20px;
      padding: 0 16px 16px 16px !important;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 7px;
        height: 16px;
        width: 4px;
        background-color: #096dd9;
        border-radius: 2px;
      }
    }
  }
}

/*多行文本截取*/
.omit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.omit-1 {
  -webkit-line-clamp: 1;
}

.omit-2 {
  -webkit-line-clamp: 2;
}

.omit-3 {
  -webkit-line-clamp: 3;
}

.no-omit {
  white-space: normal;
  word-break: break-all;
}

.no-nowrap {
  white-space: nowrap;
}

.ellipsis-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 图片弹框 */
.images-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    i {
      color: #fff;
    }
  }
}

.scroll-box {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0);
  }
}

// form 表单必填提示*号不占位置
body {
  .ant-form-item-label {
    overflow: unset;

    .ant-form-item-required {
      &:before {
        position: absolute;
        left: -10px;
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  &.align-top {
    align-items: start;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  .flex-item {
    flex: 1;
  }
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100%;
}

.ant-card {
  &.ant-card-fill {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-card-body {
      flex: 1;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
    }
  }
}

.common-hover {
  color: rgba(0, 0, 0, 0.65);

  &:hover {
    color: @primary-color;
  }
}

.swiper-list-next {
  position: relative;

  .swiper-next,
  .swiper-prev {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: @primary-color;
    color: #ffffff;
    height: 20px;
    width: 20px;
    text-align: center;
    z-index: 9;
    cursor: pointer;

    i {
      font-size: 16px;
      color: #ffffff;
    }

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .swiper-next {
    right: -16px;
  }

  .swiper-prev {
    left: -16px;
  }
}

.card-not-padding {
  > .ant-card-body {
    padding: 0;
    border: none;
  }
}

body {
  .ant-modal-body {
    padding: 16px;
  }

  .ant-card-bordered .ant-card-cover {
    margin-right: 0;
    margin-left: 0;
    transform: translateY(0);
    border-bottom: #f0f0f0 solid 1px;

    .images-auto {
    }
  }
}

.width-full {
  width: 100% !important;
}

.form-switch {
  position: absolute;
  z-index: 1;
  margin-top: 20px;
  right: 20px;
  box-shadow: 0 0 0 10px rgb(255 255 255);

  .ant-switch {
    background-color: #e6e3e3;

    &.ant-switch-checked {
      background-color: #096dd9;
    }
  }
}

.entrance {
  .tag {
    position: relative;
    background-color: #e9f2ff;
    color: #333;
    font-size: 18px;
    text-align: center;
    padding: 12px;
    cursor: pointer;
    display: block;

    &:hover,
    &.active {
      color: @primary-color;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: @primary-color;
        left: 0;
        top: 0;
      }
    }
  }
}

.recruit {
  .tag {
    display: inline-block;
    cursor: pointer;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;

    &:hover,
    &.active {
      background-color: @primary-color;
      color: #ffffff;
    }
  }
}

// 栅格内容自动填充
.row-full {
  &.ant-row {
    align-items: stretch;

    .ant-card {
      height: 100%;
    }
  }
}

//弹出框层级
// .edit-form{
//   &.ant-modal-wrap{
//     z-index: 1080;
//   }

// }

// 文字提示组件层级
.tool-box {
  &.ant-tooltip {
    z-index: 999;
  }
}

.card-item {
  padding: 25px 0;
  position: relative;
  overflow: hidden;
  text-align: center;
  background: #f7fafc;
  border: 1px solid #ededed;
  border-radius: 4px;
  font-size: 20px;

  .figure {
    font-family: "DS-Digital-BoldItalic";
    font-size: 32px;
    margin-right: 3px;
  }
}


.white4{
  color:rgba(255,255,255,0.4);
}
