@charset "UTF-8";
@import "themes/default";

/*首页走马灯*/

.home-box {
    background-size: cover;
    position: relative;

    .ant-carousel {
        height: 100%;
        width: 100%;
    }

    .ant-carousel > div {
        height: 100%;
        width: 100%;
    }

    .slick-slider > div {
        height: 100%;
        width: 100%;
    }

    .slick-list > div {
        height: 100%;
        width: 100%;
    }

    .slick-slide {
        height: 540px !important;
        width: 100%;

        & > div {
            height: 100%;
            width: 100%;

            .home-bkg {
                height: 100%;
                width: 100%;

                .home-img-box {
                    float: left;
                    width: 100%;
                    background-position: center;
                    display: table;
                    height: 100%;
                    background-size: cover !important;
                }

                .home-inner {
                    height: 100%;

                    color: #fff;
                    padding-top: 40px;

                    vertical-align: middle;
                    background-color: rgba(0, 0, 0, 0.4);

                    .home-content {
                        max-width: 1100px;
                        margin-left: auto;
                        margin-right: auto;

                        .home-title {
                            padding: 0;
                            font-size: 42px;
                            line-height: 1.4em;
                            text-align: left;
                            color: #fff;
                        }

                        .home-sub-text {
                            padding: 5px;
                            font-weight: 300;
                            line-height: 1.8em;
                            color: rgba(255, 255, 255, 0.8);
                            text-align: left;
                            font-size: 32px;
                            color: #3aa4e8;
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }

    .home-tab {
        height: 553px;

        //.ant-tabs-nav-scroll {
        //  text-align: center;
        //}
    }
}

.home-sider-img-box {
    position: relative;
    height: 217px;
    margin: 15px;
    overflow: hidden;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
        .home-sider-tit-content {
            bottom: 0;
        }

        .home-sider-img-tit {
            transform: translateY(100%);
        }
    }
}

.home-sider-img-tit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    line-height: 44px;
    padding: 0 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    transition: all 0.2s;
}

.home-sider-tit-content {
    position: absolute;
    bottom: -100%;
    height: 100%;
    width: 100%;
    padding: 5%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.5s;

    .home-sider-tit1 {
        font-size: 18px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .home-sider-tit-p {
        font-size: 13px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

/*首页走马灯-end*/

/*首页*/
.home-navigation {
    position: absolute;
    height: 100%;
    width: 10%;
    // top: 10%;
    z-index: 9;
    overflow: hidden;
}
.home-notice {
    .ant-tag {
        width: 71px;
        height: 32px;
        font-size: 14px;
        border-radius: 4px;
        line-height: 32px;
        text-align: center;
        color: #666666;
        background: rgba(238, 238, 238, 1) !important;
    }
}
.home-content {
    .ant-btn {
        // width: 220px;
        &:hover {
            color: #fff;
            background-color: @primary-color;
            border-color: @primary-color;
        }
    }
}

.home-text-content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .index-price-img {
        .images-auto {
            border-radius: 0;
        }
    }
}
.index-activity-card {
    border: 1px solid rgba(229, 229, 229, 1);
    &:hover {
        .title {
            color: @primary-color !important;
        }
    }
    &.ant-card-small > .ant-card-body {
        padding: 21px 18px;
    }
}
.home-arrow {
    display: block;
    height: 100%;
    width: 90%;
    transition: all 0.2s ease-out;
}

.home-img-prev {
    left: 0;

    &:hover {
        .home-arrow {
            opacity: 0.5;
            margin: 0px 0px 0px 20%;
            cursor: pointer;
        }
    }

    .home-arrow {
        opacity: 0;
        margin: 0px 0px 0px -20%;
        font-size: 50px;
        color: #fff;
        display: flex;
        align-items: center;
    }
}

.home-img-next {
    right: 0;

    &:hover {
        .home-arrow {
            opacity: 0.5;
            margin: 0 0 0 20%;
            cursor: pointer;
        }
    }

    .home-arrow {
        opacity: 0;
        margin: 0 0 0 70%;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 50px;
        justify-content: center;
    }
}

.home-header {
    .home-description {
        font-size: 12px;
        line-height: 2;
        color: #9b9b9b;
        text-align: center;
        margin: 13px 0 0;
    }
}

.index-container {
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;

    .home-item {
        background-color: #fff;
        box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        margin: 15px;

        .home-activity-set {
            position: relative;
            height: 217px;
            margin-bottom: 2rem;
            overflow: hidden;
            cursor: pointer;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

.home-features-title {
    font-size: 16px;
    color: #262626;
    margin: 8px 0 -10px;
    font-weight: bold;
}

.home-new {
    .newtime {
        font-size: 12px;
        position: relative;
        margin: 0;
        padding-left: 15px;

        span {
            background: #cccccc;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            position: absolute;
            left: -4px;
            top: 5px;
        }

        label {
            font-weight: normal;
            margin: 0 2% 0 2%;
            font-size: 14px;
            display: inline-block;
        }
    }

    .box-bor {
        border-left: 2px solid #cccccc;
        padding-left: 15px;
        margin-top: -6px;
        padding-top: 15px;

        .home-news-title {
            color: #333333;
            font-size: 18px;
        }

        .home-news-text {
            color: #9e9e9e;
            font-size: 14px;
        }
    }
}

.home-body {
    position: relative;
    background-color: #fff;
}

.home-content {
    position: relative;
    overflow: hidden;
    padding: 60px 0;
}

.homt-title {
    position: relative;
    overflow: hidden;
    text-align: center;
    font-size: 36px;
    color: #000000;
    line-height: 34px;
    padding: 30px 0 30px;
    margin-bottom: 40px;
    &::after {
        position: absolute;
        content: "";
        width: 42px;
        border-radius: 2px;
        height: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2289cb;
        bottom: 16px;
    }
}

.home-service-list {
    position: relative;
}

.home-service-row {
    position: relative;
    display: flex;
    align-items: center;
    height: 122px;
    border-radius: 16px;
    color: #333;
    margin-bottom: 30px;
    border: 1px solid #f0f0f0;
    transition: all 0.3s;
    background-color: @white-color;
    // cursor: pointer;
    > a {
        padding: 20px 25px;
        color: #333;
        display: block;
        width: 100%;
        height: 100%;
        &:hover {
            color: #fff;
            .gray-tow-color{
                color:@white-color !important;
            }
        }
    }

    > div {
        width: 100%;
    }

    .size-20 {
        line-height: 1;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background-color: @primary-color;
        color: #fff;
    }

    .home-service-a {
        cursor: pointer !important;
        background-color: #2eaaf9;
        width: 40px;
        height: 18px;
        line-height: 12px;
        font-size: 14px;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.home-service-title {
    position: relative;
    overflow: hidden;
    padding: 15px 20px 15px 22px;
    margin-bottom: 10px;

    a {
        height: 20px;
        width: 20px;
        text-align: center;
        display: block;
        border: solid 1px #d2d2d2;
        line-height: 12px;
        background-color: @white-color;

        i {
            font-size: 12px;
        }
        &:hover{
            background-color: @primary-color;
            color:@white-color;
        }
    }

    &::after {
        content: "";
        position: absolute;
        height: 16px;
        width: 8px;
        background-color: @primary-color;
        border-radius: 8px;
        left: 4px;
        top: 26px;
    }
}

.swiper-home-politique {
    height: 420px;

    .swiper-slide {
        position: relative;
        width: auto;
        border-bottom: solid 1px #e8e8e8;
        // cursor: pointer;
        transition: all 0.3s;
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            h4 {
                color: @primary-color;
            }
        }

        .ant-row {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translate(0, -50%);
        }

        .ant-tag {
            transition: none;
            height: 26px;
            line-height: 26px;
            font-size: 14px;
            color: #0e0e0e;
            border: navajowhite;
            width: auto !important;
            background-color: rgba(229, 229, 229, 1);
            &.ant-tag-has-color {
                color: #fff;
            }
        }
    }
}

.home-date-box {
    border-radius: 5px;
    background-color: #fafafa;
    width: 73px;
    height: 73px;
    text-align: center;

    > div {
        height: 100%;
    }

    .day {
        color: #000;
        line-height: 1.1;
    }
}

.home-park {
    .ant-list-split {
        .ant-list-items {
            a {
                &:last-child {
                    .ant-list-item {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .ant-list-item {
            padding: 16px;
            border-bottom: 0;
            cursor: pointer;
            margin-bottom: 35px;
            border-radius: 4px;

            .ant-typography {
                line-height: 28px;
                height: 86px;
            }

            .ant-list-item-meta-title {
                font-size: 22px;
                line-height: 25px;
                height: 50px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-bottom: 0;
            }

            &:hover {
                .ant-list-item-meta-title {
                    color: @primary-color !important;
                }
            }
        }
    }
}
.home-dynamic-icon {
    line-height: 14px;
    i {
        vertical-align: bottom;
    }
}
.enterprises-list {
    position: relative;
    text-align: center;
    height: 245px;
}

.enterprises-list-row {
    display: inline-block;
    vertical-align: middle;
    width: 165px;
    text-align: center;
    transition: all 0.3s;
    height: 215px;
    padding-top: 12px;

    &:hover {
        padding: 0;

        i {
            background-color: @primary-color;
            color: #fff;
            height: 160px;
            width: 160px;
        }
        span {
            margin-top: 10px;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    i {
        position: relative;
        display: inline-block;
        width: 120px;
        height: 120px;
        background-color: #fafafa;
        border-radius: 50%;
        font-size: 40px;
        color: #333;
        transition: all 0.3s;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    span {
        display: block;
        line-height: 21px;
        font-size: 22px;
        margin-top: 30px;
        color: #000;
        transition: all 0.3s;

        &::before {
            content: "";
            position: relative;
            top: -5px;
            margin: 0 auto;
            width: 10px;
            height: 2px;
            background-color: @primary-color;
            display: none;
        }
    }
}
.notice-title {
    position: relative;
    height: 64px;
    line-height: 64px;
    width: 125px;
    color: #010101;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        height: 24px;
        width: 1px;
        background-color: #8c8c8c;
        transform: translate(-50%, -50%);
    }
}
.home-modal {
    .ant-modal-content {
        height: 100%;
        .ant-modal-body {
            height: calc(~"100% - 108px");
            overflow: auto;
            &::-webkit-scrollbar {
                width: 9px;
                height: 9px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
            }
            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
}
@media (max-width: 1366px) {
    .home-box {
        zoom: 0.8;
    }
}

.a-title {
    color: black;
    font-size: 18px;

    &:hover {
        color: @primary-color;
    }
}

/*首页 -- end*/


/*新版首页*/
.home-root{
    .header-logo{
        background: url("../images/zjk/logo.png") no-repeat left center;
    }
    .head-menu{
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
    }
    .header{
        background-color: rgba(3,25,81,0.1);
        .ant-select{
            color:@white-color;
        }
        .ant-select-arrow{
            color:rgba(255,255,255,0.4);
        }
        .container .ant-menu .ant-menu-item > a{
            color:@white-color;
        }
        .container .ant-menu .ant-menu-item > a,
        .container .ant-menu .ant-menu-submenu span,
        .ant-menu li:hover > a{
            color:@white-color !important;
        }
        .ant-menu li:hover::after,
        .ant-menu li.ant-menu-item-selected::after,
        .ant-menu li.ant-menu-submenu-selected::after,
        .ant-menu li.ant-menu-submenu-active::after{
            background: @white-color;
            //bottom:0;
            //border-radius: 0;
        }
        .container .ant-menu .ant-menu-submenu::before{
            color:@white-color;
        }
        .content-right{
            color:@white-color;
            a{
                color:@white-color;
            }
        }
        .home-search{
            background: none;
            input{
                &::-webkit-input-placeholder{
                    color:@white-color;
                }
            }
            a{
                background: none;
                width: 50px;
            }
        }
    }
    .header-logo:after{
        background-color: @white-color;
    }
    .ant-affix{
        .header{
            background-color: rgba(3, 25, 81, 0.5);
        }
    }
}
.home-title{
    text-align: center;
}

.home-tag {
    text-align: center;
    span {
        display: inline-block;
        height: 44px;
        line-height: 44px;
        width: 214px;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 18px;
        border-radius: 18px;
        cursor: pointer;
        position: relative;
        &:before{
            content:"/";
            position: absolute;
            left: -2px;
            top:0;
            color:rgba(0,0,0,0.2);
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
        &:hover {
            color: @primary-color;
        }
        &.active {
            color: @primary-color;
            background: url("../images/zjk/line-bg.png") no-repeat center;
            &:after{
                content:"";
                position: absolute;
                left: 50%;
                bottom:0;
                width: 30px;
                height: 3px;
                background-color: @primary-color;
                transform: translateX(-50%);
            }
        }
    }
}
.home-news-swiper.swiper-container{
    padding-bottom: 70px;
    .swiper-slide {
        height: calc((100% - 48px) / 2);
    }
    .swiper-pagination-bullet-active{
        width: 24px;
        border-radius: 8px;
        background-color: @primary-color;
    }
}
.news-swiper-item{
    .news-swiper-content{
        flex: 1;
    }
}
.custom-color1{
    color:rgba(87,107,245,0.6) !important;
}
.custom-color2{
    color:#b58741 !important;
}
.foundation-content{
    padding: 150px 0 150px 13.5%;
    background: url("../images/zjk/jc-bg.jpg") no-repeat;
    background-size: cover;
    display: flex;
    .foundation-title{
        width: 280px;
        .home-title{
            text-align: left;
        }
    }
    .home-foundation-swiper{
        flex: 1;
    }
}
.title-line{
    display: inline-block;
    width: 16px;
    height: 6px;
    background-color: #5f4fff;
    margin-right: 15px;
    vertical-align: 5px;
}
.foundation-pagination{
    position: relative;
    margin-top: 100px;
    height: 60px;
    .swiper-pagination{
        text-align: left;
        font-size: 26px;
        width: auto;
        left: 10px;
        .swiper-pagination-current{
            font-size: 32px;
            color:@primary-color;
            vertical-align: 10px;
        }
    }
}
.foundation-navigation{
    position: relative;
    .swiper-button-prev, .swiper-button-next{
        width: 48px;
        height: 48px;
        background-color: @white-color;
        color:@gray-one-color;
        left: 0;
        &:hover{
            background-color: @primary-color;
            color:@white-color;
        }
        &:after{
            font-size: 24px;
        }
    }
    .swiper-button-next{
        right:auto;
        left: 49px;
    }
}
.home-foundation-card{
    box-shadow: 0 6px 16px 0 rgba(32, 79, 221, 0.3);
    height: 440px;
    overflow: hidden;
    .ant-card-body{
        padding: 20px 25px;
    }
}
.home-foundation-number{
    position: absolute;
    right: 28px;
    bottom:-24px;
    font-size: 68px;
    color:#f2f3f6;
    line-height: 1;
    font-weight: bold;
}
.custom-tag{
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    background-color: #e9f1ff;
    color:@primary-color;
    font-size: 14px;
    border-radius: 16px;
}
.home-policy-swiper.swiper-container{
    padding:10px 10px 70px;
    .swiper-slide {
        height: calc((100% - 20px) / 2);
    }
    .swiper-pagination-bullet-active{
        width: 24px;
        border-radius: 8px;
        background-color: @primary-color;
    }
}
.home-policy-card{
    border-radius: 4px;
    box-shadow: 0 4px 10px 0 rgba(32, 79, 221, 0.21);
    position: relative;
    overflow: hidden;
    .ant-card-body{
        height: 180px;
        padding: 25px 30px;
    }
    .arrow{
        position: absolute;
        left: 0;
        top:0;
    }
    .ant-card-actions{
        background: #f0f6ff;
        border-top-color: #d9e7ff;
        li{
            border-right: #d9e7ff solid 1px !important;
            &:last-child{
                border-right: none !important;
            }
            span{
                font-size: 16px;
                color:@gray-two-color;
            }
        }
    }
}
.home-activity-content{
    padding: 70px 0;
}
.activity-swiper-item{
    .ant-col-5 {
        display: block;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.home-notice{
    background-color: #fafafa;
    padding: 15px 0;
}
.notice-swiper {
    .swiper-slide {
        width: calc((100% - 70px) / 2);
        display: flex;
        align-items: center;
        a {
            color: @gray-one-color;
        }
    }
}
.home-notice-title{
    font-size: 24px;
    position: relative;
    &:after{
        content: "";
        position: absolute;
        right:14px;
        top:7px;
        width: 1px;
        height: 24px;
        background-color: #999;
    }
}
.dot-icon{
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #c89d5c;
    margin-right: 10px;
    vertical-align: 3px;
}
.top-notice-more.ant-btn{
    background-color: transparent;
    border-radius: 4px;
    color:@gray-two-color;
}
.top-notice-date{
    font-style: italic;
    font-size: 15px;
    color:#6f6f6f;
    margin-left: 10px;
}
.home-news-list{
    .home-news-list-item{
        margin-bottom: 60px;
    }
}
.home-more-btn{
    text-align: center;
    a{
        display: inline-block;
        width: 220px;
        height: 40px;
        border-radius: 20px;
        border:#f4ebde solid 1px;
        color:#c8974c;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
    }
}
.news-content{
    padding: 70px  0;
}
.park-content{
    background: url("../images/zjk/fw-bg.jpg") no-repeat center bottom;
    background-size: cover;
    padding: 70px 0 140px;
}
.home-policy-item{
    border-bottom: #bebebe dashed 1px;
    padding: 20px 0;
    &:first-child{
        padding-top: 0;
    }
    &:last-child{
        border-bottom: none;
    }
}
.home-policy-sy{
    display: inline-block;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 4px;
    margin-left: 5px;
    &.state1{
        background-color: #e5e5e5;
        color:#000;
    }
    &.state2{
        background-color: #e82121;
        color:@white-color;
    }
    &.state3{
        background-color: #279d1e;
        color:@white-color;
    }
}
.home-policy-title{
    .a-title{
        display: inline-block;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    *{
        vertical-align: top;
    }
}
.home-activity-img{
    position: relative;
    span{
        position: absolute;
        right:0;
        bottom:0;
        width: 64px;
        height: 64px;
        text-align: center;
        line-height: 64px;
        font-size: 16px;
        color:@white-color;
        &.state1{
            background-color: #d79d45;
        }
        &.state2{
            background-color: #7545fe;
        }
        &.state3{
            background-color: #a9a49d;
        }
    }
}
.home-activity-swiper.swiper-container{
    padding: 0 10px;
    .swiper-pagination-bullet{
        width: 12px;
        height: 12px;
        border-radius: 0;
    }
    .swiper-pagination-bullet-active{
        background-color: #b58741;
    }
}
.home-activity-card{
    height: 410px;
    &:hover{
        box-shadow: 0 4px 12px 0 rgba(35, 35, 35, 0.1);
    }
}
.home-party-content{
    background: url("../images/zjk/dj-bg.jpg") no-repeat center bottom;
    background-size: cover;
    padding: 70px 0;
}
.home-party-item{
    display: flex;
    .home-party-img{
        width: 50%;
    }
    .home-party-write{
        width: 50%;
        padding: 25px;
        background-color: @white-color;
    }
}
.home-party-row{
    .ant-col{
        &:nth-child(3),&:nth-child(4){
            .home-party-item{
                flex-flow: row-reverse;
            }
        }
    }
}
.home-party-box{
    position: relative;
    height: 400px;
    .swiper-button-prev, .swiper-button-next{
        width: 48px;
        height: 48px;
        background-color: @white-color;
        color:@gray-one-color;
        left: -80px;
        box-shadow: 0px 4px 6px 0px rgba(38, 38, 38, 0.15);
        &:hover{
            background-color: @primary-color;
            color:@white-color;
        }
        &:after{
            font-size: 24px;
        }
    }
    .swiper-button-next{
        left: auto;
        right:-80px;
    }
}
.home-party-swiper{
    .swiper-slide{
        box-shadow: 0px 7px 29px 0px rgba(149, 118, 78, 0.42);
    }
}
.institution-content{
    padding: 80px 0;
}
/*新版首页end*/

@media (max-width: 1500px) {
}
